import * as React from "react";
import { type SVGProps } from "react";

const UserFormIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={22}
    viewBox="0 0 19 22"
    fill="none"
    {...props}
  >
    <path
      stroke="#E02132"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.5 10.556a4.722 4.722 0 1 0 0-9.445 4.722 4.722 0 0 0 0 9.445ZM6.667 12.389h5.666A5.667 5.667 0 0 1 18 18.055v2.834H1v-2.834a5.667 5.667 0 0 1 5.667-5.666Z"
    />
  </svg>
);
export default UserFormIcon;
